import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import Card from "components/card";
import { Link, graphql } from "gatsby";
import CpuTable from "components/cpuTable";
import PassMarkSearch from 'components/passMarkSearch';
import BasicTable from "components/basicTable";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "daigakusei"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/daigakusei.png"}) { ...eyecatchImg },
    intelprocessor_s: file(relativePath: { eq: "cpu/intel-processor_s.png"}) { ...smallImg },
    ccpuhikaku: file(relativePath: { eq: "cover/cpu/cpu-hikaku.png"}) { ...eyecatchImg },
    noteconnect: file(relativePath: { eq: "tanshi/note-connect.png"}) { ...normalImg },
    heavypc: file(relativePath: { eq: "pc/heavypc.png"}) { ...normalImg },
    science_humanities: file(relativePath: { eq: "student/science_humanities.webp"}) { ...normalImg },
    drawing: file(relativePath: { eq: "student/drawing.webp"}) { ...normalImg },
    presenting: file(relativePath: { eq: "student/presenting.png"}) { ...normalImg },
    microsoftoffice_s: file(relativePath: { eq: "pc/microsoft-office.png"}) { ...smallImg },
    convertible_s: file(relativePath: { eq: "pc/convertible_s.png"}) { ...smallImg },
    allDaigakuseiJson {
      nodes {
        name
        pm
        clock
        core
        thread
        tdp
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`【４年間余裕】大学生へおすすめのノートパソコンとスペック`}</h1>
    <time itemProp="datePublished" dateTime="Fri Jan 24 2025 23:40:06 GMT+0900 (日本標準時)">更新日:2025年1月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="大学生へおすすめのノートパソコン" mdxType="Image" />
    <p>{`大学の生協では毎年新学期前になると、新生活応援セールとして新入生向けのパソコンが一斉に売りに出される。`}</p>
    <p>{`一見良心的に見える価格帯であるが、玄人からすると`}<strong parentName="p">{`無難なPCが手厚い保証やサービスとともに販売されるいわゆる`}<em parentName="strong">{`情弱向けの製品`}</em></strong>{`にしか見えない場合がほとんどである。要するに掴まされている。`}</p>
    <p>{`また、オンラインセミナーや就職面接の増加にも伴い、必要なパソコンのスペックに対する要求は上がってきている事を考えると、生協や量販店などで掴まされたパソコンはスペック不足で就職活動の頃には使い物にならない可能性も高い。`}</p>
    <p>{`そこで本記事では、現役ソフトウェアエンジニアの管理人が、`}<strong parentName="p"><em parentName="strong">{`軽い、速い、４年間安心して使用できる`}</em>{`という観点で、大学生へおすすめのパソコンを紹介`}</strong>{`する。`}</p>
    <p>{`また、本稿では汎用性の高さから`}<em parentName="p">{`モバイルノート`}</em>{`を推薦するが、デスクトップパソコンが欲しいなど幅広い用途を考えている場合には`}<a href="/">{`パソコン選び方ガイドトップ`}</a>{`のパソコン診断なども参考にして考えてみよう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "大学生向けのパソコンに求められるスペック",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%A4%A7%E5%AD%A6%E7%94%9F%E5%90%91%E3%81%91%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AB%E6%B1%82%E3%82%81%E3%82%89%E3%82%8C%E3%82%8B%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF",
        "aria-label": "大学生向けのパソコンに求められるスペック permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`大学生向けのパソコンに求められるスペック`}</h2>
    <p>{`それでは早速どのようなパソコンが求められるかを一つ一つ丁寧に解説していこうと思う。`}</p>
    <h3 {...{
      "id": "1kg以下の重量",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1kg%E4%BB%A5%E4%B8%8B%E3%81%AE%E9%87%8D%E9%87%8F",
        "aria-label": "1kg以下の重量 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1kg以下の重量`}</h3>
    <p>{`大学生は広いキャンバス内を授業の度に移動しなければならない上、高い金額を出して購入した教授が書いた分厚い本も持ち歩かなければならない。当然ロッカーなどは無い。`}</p>
    <p>{`このような状況で更にパソコンまで持ち運ぶとなるとかなりしんどい。会社の営業職でもない限りこのような過酷な状況は社会人でも無い。`}</p>
    <p>{`大学生は若さはあるとは言え、`}<strong parentName="p"><em parentName="strong">{`長い受験勉強により体は貧弱となっており、重いパソコンを毎日持ち運べる体とは言い難い`}</em></strong>{`。己を過信し過ぎないことである。`}</p>
    <p>{`そこで軽いことまずは重要である。具体的には`}<strong parentName="p"><em parentName="strong">{`1kgを切るレベルが頻繁に持ち運ぶにはおすすめ`}</em></strong>{`である。滅多に持ち歩かない場合は1.5kg程度でも我慢はできる。`}</p>
    <Image {...props} name="heavypc" alt="重いパソコンは大学生活に苦労する" mdxType="Image" />
    <h3 {...{
      "id": "133インチ-14インチ程度のサイズ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#133%E3%82%A4%E3%83%B3%E3%83%81-14%E3%82%A4%E3%83%B3%E3%83%81%E7%A8%8B%E5%BA%A6%E3%81%AE%E3%82%B5%E3%82%A4%E3%82%BA",
        "aria-label": "133インチ 14インチ程度のサイズ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`13.3インチ-14インチ程度のサイズ`}</h3>
    <p>{`授業や大学図書館、就職や研究活動でパソコンを持ち運ぶことが前提となるので、選ぶとすると`}<strong parentName="p"><em parentName="strong">{`13.3インチ-14インチぐらいのモバイルノートが主流`}</em></strong>{`である。それ以上は大抵カバンに入らない。`}</p>
    <p>{`1インチは2.54cmであり、画面のサイズは対角線の長さで表されるため、14インチだと画面の左下から右上まで35.6cmといったところである。`}</p>
    <p>{`また同じインチであっても解像度の違いにより縦と横の長さが異なり、画面サイズに加え、縦横比も使い勝手に少なからず影響してくる。`}</p>
    <p>{`そこで典型的なサイズと解像度、縦横の幅(cm)及び特長やどのような用途に適しているかを下記に簡単にまとめた。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>サイズ</th>
      <th>解像度</th>
      <th>縦幅</th>
      <th>横幅</th>
      <th>特長・用途</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>13.3インチ</td>
      <td>1920x1080</td>
      <td>16.6</td>
      <td>29.4</td>
      <td>軽量コンパクト</td>
    </tr>
    <tr>
      <td>13.4インチ</td>
      <td>1920x1200</td>
      <td>18.0</td>
      <td>28.9</td>
      <td>Webサイト閲覧、文書作成</td>
    </tr>
    <tr>
      <td>14インチ</td>
      <td>1920x1080</td>
      <td>17.4</td>
      <td>31.0</td>
      <td>映像編集、動画視聴</td>
    </tr>
    <tr>
      <td>14インチ</td>
      <td>1920x1200</td>
      <td>18.8</td>
      <td>30.2</td>
      <td>多くの作業に快適</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`基本的にサイズが大きいほど快適に使え、`}<strong parentName="p"><em parentName="strong">{`横長だと映像視聴、縦長だと作業に向く`}</em></strong>{`。`}</p>
    <p>{`新大学生の多くはこれからパソコンを使って何をしたいのか？何ができるのか？が分からないと思われるので、`}<strong parentName="p"><em parentName="strong">{`失敗が少ないという観点では14インチがベスト`}</em></strong>{`だと感じられるが、`}<strong parentName="p"><em parentName="strong">{`携帯しやすさとのトレードオフなので最後は自身で判断しよう`}</em></strong>{`。0.7インチ差はそこまで大きな差ではないので失敗したとしても軽微である。`}</p>
    <p>{`ちなみに12.5インチともう一回り小さいパソコンもあり、カフェや講義室など机や人の間隔が狭いスペースで扱い安いが、万人向けではない。ネットサーフィンと文章作成だけをするのであれば問題ないが、`}<strong parentName="p"><em parentName="strong">{`最初に購入するパソコンとして12.5インチはリスクが高い`}</em></strong>{`と言える。`}</p>
    <SmallBox type="word" text="15.6インチやデスクトップパソコンは?" mdxType="SmallBox">
確かに家でしかパソコンを使わない場合は、作業領域が大きい15インチ以上のノートパソコンやデスクトップパソコンを使う方が作業を行いやすい。<br /><br />
しかし結局ゼミや就職活動時にモバイルノートが必要になるケースが多く、買いなおす必要性が出てきてしまうであろう。<br /><br />
本格的な3Dゲームをやりたい場合はモバイルノートの他にゲーミングデスクトップPCを買う必要があるだろう。
ハイスペックなゲーミングPCをメインで使い、外に行くときはややスペックの落としたノートパソコンを使うという手もある。
    </SmallBox>
    <h3 {...{
      "id": "12時間以上のバッテリー持続時間",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#12%E6%99%82%E9%96%93%E4%BB%A5%E4%B8%8A%E3%81%AE%E3%83%90%E3%83%83%E3%83%86%E3%83%AA%E3%83%BC%E6%8C%81%E7%B6%9A%E6%99%82%E9%96%93",
        "aria-label": "12時間以上のバッテリー持続時間 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12時間以上のバッテリー持続時間`}</h3>
    <p><strong parentName="p">{`キャンバス内でバッテリーを確保するのは大変`}</strong>{`な場合も多い。一日図書館に籠りレポートを仕上げられるだけのバッテリー寿命は欲しいところである。`}</p>
    <p>{`公称値10時間のバッテリーは6~7時間程度しか持たない事が多い。またバッテリーは次第に劣化して行くので`}<strong parentName="p"><em parentName="strong">{`4年間を考えると12時間以上公称値としてあるとそれなりに安心`}</em></strong>{`できる。`}</p>
    <h3 {...{
      "id": "CPUはPASSMARK-12500点以上",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AFPASSMARK-12500%E7%82%B9%E4%BB%A5%E4%B8%8A",
        "aria-label": "CPUはPASSMARK 12500点以上 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUはPASSMARK 12500点以上`}</h3>
    <p>{`CPUはパソコンの頭脳にあたり、計算演算処理を担当する重要なパーツである。インテルが有名で、CoreシリーズのCPUをパソコンメーカーに提供している。`}</p>
    <p>{`ただ例えば同じCore i5でも、世代(設計された年)や用途などで複数種類あり、`}<strong parentName="p"><em parentName="strong">{`Core i5なので高性能と書かれていたとしても、３年前のもので微妙な性能だったみたいな失敗は初心者にありがち`}</em></strong>{`である。`}</p>
    <p>{`このような事態にならないために、本サイトでは`}<strong parentName="p">{`CPUの総合的な性能評価指標である`}<em parentName="strong">{`PASSMARKスコア`}</em>{`(下にリンクあり)を見ることをおすすめ`}</strong>{`している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`4年間(もしくは6年間)快適に過ごすためには12500点以上は欲しい`}</em></strong>{`ところである。大学時代は自身の可能性を広げる重要な時期でもあるため、低スペックなPCがクリエイティビティや生産性の足枷となることは避けたい。`}</p>
    <p>{`下記におすすめのCPUをピックアップしたので参考にして欲しい。統計誤差により下位の型番が上位と逆転していることもあるのでコア数なども参考に真の実力は判断してほしい。ともあれこのリストにあるCPUならば間違いはない。`}</p>
    <CpuTable cpuData={props.data.allDaigakuseiJson.nodes} mdxType="CpuTable" />
    <p>{`末尾の文字にH、P、U、Vなどのシリーズが書かれているが、`}<strong parentName="p"><em parentName="strong">{`HとP、Vはグラフィック系が特に強化されており、3Dゲームをしたい人や、本格的な動画編集をしたい人はこのシリーズがおすすめ`}</em></strong>{`である。Youtuberを目指すならばHかVであろう。`}</p>
    <p>{`一方で`}<strong parentName="p"><em parentName="strong">{`Uは低消費電力でバッテリー寿命が長い`}</em></strong>{`、日常遣いには適しているが、クリエイティブな重い作業はやや苦手といったところ。とはいえそれほど気にすることはないだろう。`}</p>
    <p>{`CPUの全リストをこちらで載せているので興味のある方は別途参考にして欲しい。`}</p>
    <Card {...props} title="CPU性能比較表" description="PASSMARK12500点以上を一つの基準としてパソコンを探していこう。" image="ccpuhikaku" path="/cpu/" mdxType="Card" />
    <p>{`あるいは簡易検索としてこちらに型番を入力して調べても良い。Intelのライバル企業AMDのCPUである`}<em parentName="p">{`Ryzen`}</em>{`のスペックもこちらで検索可能である。`}</p>
    <PassMarkSearch mdxType="PassMarkSearch" />
    <h3 {...{
      "id": "メモリは8GBか16GB",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF8GB%E3%81%8B16GB",
        "aria-label": "メモリは8GBか16GB permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリは8GBか16GB`}</h3>
    <p>{`メモリとは立ち上げているアプリケーションの状態を保持するために使う機能であり、足りないと複数のアプリケーションを立ち上げた時に急に終了してしまったり、動作が極端に遅くなったりする。`}</p>
    <p>{`4GBだと心もとないので、`}<strong parentName="p"><em parentName="strong">{`基本的には8GBをおすすめ`}</em></strong>{`する。16GBは複数アプリの起動やプログラミング、動画編集等でより動作の安定を図れるが、価格との相談である。`}</p>
    <p>{`強いて言うならば、`}<strong parentName="p"><em parentName="strong">{`情報系学生はアプリケーションを自身で作りたくなる人が多いので本格的な開発環境を備えるならば16GBのメモリがあると好ましい`}</em></strong>{`。`}</p>
    <p>{`一応用途ごとに必要なメモリ容量は記載しておく。32GBを求めるならばデスクトップPCの別途購入をおすすめする。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th className="no-wrap">メモリ</th>
      <th>用途</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>4GB</td>
      <td>軽い事務作業、ブラウザのタブ４つ程度</td>
    </tr>
    <tr>
      <td>8GB</td>
      <td>ブラウザのタブ大量、そこそこのマルチタスク、FullHDまでの映像編集、軽めの3Dゲーム</td>
    </tr>
    <tr>
      <td>16GB</td>
      <td>超マルチタスク、4Kでのゲームプレイ、重めの3Dゲーム(別途グラフィックボードが必要)、Adobe After Effects、Adobe Premiere Proでの本格映像編集、仮想デスクトップ(プログラミング用途)、Androidシュミレータ(プログラミング用途)</td>
    </tr>
    <tr>
      <td>32GB</td>
      <td>4K動画編集、3Dモデリングの長時間プレビュー再生、大規模データを用いたAIの学習</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "USB-TypeA端子があること",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#USB-TypeA%E7%AB%AF%E5%AD%90%E3%81%8C%E3%81%82%E3%82%8B%E3%81%93%E3%81%A8",
        "aria-label": "USB TypeA端子があること permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`USB TypeA端子があること`}</h3>
    <Image {...props} name="noteconnect" alt="様々なPCの端子" mdxType="Image" />
    <p>{`データの受け渡しはUSBメモリで行うことが多い。`}<strong parentName="p"><em parentName="strong">{`USBメモリや無線マウスの受信機などはType Aが多いため、ハイエンドのモデルではしばしば省かれるこの端子がついてることは重要`}</em></strong>{`である。`}</p>
    <p>{`また、Type-Cは拡張性が高く、USBのハブとして使うこともできるし、充電や映像出力にも使え汎用性が高い。最近のPCには必ず搭載されているはずである。`}</p>
    <p>{`ただ、`}<strong parentName="p">{`すべてのType-Cで映像出力に対応しているわけではなく、映像出力に対応したものは`}<em parentName="strong">{`Thunderbolt`}</em>{`や`}<em parentName="strong">{`DisplayPort Alt Mode`}</em>{`と使用に書かれている`}</strong>{`ので念のため確認しておこう。`}</p>
    <p>{`さらに、`}<strong parentName="p"><em parentName="strong">{`HDMI端子がついていることもポイントが高い`}</em></strong>{`。モニターやプロジェクターの端子として最も普及しているものがHDMI端子である。`}</p>
    <p>{`端子がなくとも変換器や`}<a href="https://amzn.to/3GTpatx" target="_blank" rel="nofollow noopener">{`USB TypeCハブ`}</a>{`など都合のよいものを適宜購入すれば最悪問題はないが、やはり持ち歩くとなると面倒である。`}</p>
    <h3 {...{
      "id": "BDDVDドライブ光学ドライブは外付けに任せる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#BDDVD%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96%E5%85%89%E5%AD%A6%E3%83%89%E3%83%A9%E3%82%A4%E3%83%96%E3%81%AF%E5%A4%96%E4%BB%98%E3%81%91%E3%81%AB%E4%BB%BB%E3%81%9B%E3%82%8B",
        "aria-label": "BDDVDドライブ光学ドライブは外付けに任せる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BD/DVDドライブ(光学ドライブ)は外付けに任せる`}</h3>
    <p>{`CDから音源を取り込む、あるいは宴会用のムービーを作成するといった用途でDVDドライブが必要になることはある。`}</p>
    <p>{`だが外出したときに使うことはあまりないし、ドライブを搭載することにより重くなり、パソコンの故障率も高くなる。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`ドライブは外付けに任せるべき`}</em></strong>{`であり、パソコン本体とは分離してリスクを減らすべきである。`}</p>
    <h3 {...{
      "id": "ストレージの容量がSSDで512GB",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8%E3%81%AE%E5%AE%B9%E9%87%8F%E3%81%8CSSD%E3%81%A7512GB",
        "aria-label": "ストレージの容量がSSDで512GB permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ストレージの容量がSSDで512GB`}</h3>
    <p>{`ストレージは文書ファイルや動画、写真を保存する領域であり、パソコンの電源を切っても保存されるものである。`}</p>
    <p>{`大学生活では写真や動画を撮りパソコン内に保存することが多い。またゲームをインストールするとごそっと50GB程度容量をとられるものも多い。`}</p>
    <p>{`Google Photoなどクラウドストレージを使う選択肢もあるが、大量に写真や動画を撮りためるとなると無料枠はすぐに突破してしまう。`}</p>
    <p>{`6年間程度パソコンを使うとすると、使い方によっては容量が足りなくなる可能性は高いため、`}<strong parentName="p"><em parentName="strong">{`余裕を持つためには512GBの容量をおすすめ`}</em></strong>{`する。`}</p>
    <p>{`ただ、外付けのハードディスクやUSBメモリにパソコン内のデータを移すことができるため、やりくりは必要となるが、`}<strong parentName="p"><em parentName="strong">{`予算との兼ね合いで256GBでも良い`}</em></strong>{`だろう。`}</p>
    <h3 {...{
      "id": "Microsoft-Office-Home-and-Businessが付属",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Microsoft-Office-Home-and-Business%E3%81%8C%E4%BB%98%E5%B1%9E",
        "aria-label": "Microsoft Office Home and Businessが付属 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Microsoft Office Home and Businessが付属`}</h3>
    <Image {...props} name="microsoftoffice_s" alt="オフィスソフト" mdxType="Image" />
    <p>{`文書作成ソフトのWord、表計算ソフトのExcel、プレゼンテーション資料作成のPowerPointは学生がほぼ必ず使うことになるソフトである。`}</p>
    <p>{`レポート作成はWord、実験で数値を整理をする時にExcel、ゼミでの発表でPowerPointといった感じである。`}</p>
    <p>{`よく使われるので、例えば先輩から去年の課題のまとめをパワポでもらい改変して出すといった状況でパワポが無いと詰むことことになる。`}</p>
    <p>{`また、`}<strong parentName="p"><em parentName="strong">{`ExcelとPowerPointは社会人になってからも使う機会がきっとある`}</em></strong>{`ので大学生の間にある程度使い慣れておいた方が良い。`}</p>
    <p>{`パワーポイントで資料を作成できない新入社員が私の会社に来たものならば、頭を抱えることになるので、是非若いうちから使っておいて欲しい(願望)。`}</p>
    <p>{`Microsoft Officeには一般販売されているものとして`}<em parentName="p">{`Office Personal`}</em>{`と`}<em parentName="p">{`Office Home and Business`}</em>{`の2種類があるが、Office PersonalはWord/Excel/Outlookの入ったパッケージでPowerPointが含まれていないため`}<strong parentName="p"><em parentName="strong">{`Office Home and Businessを選択しよう`}</em></strong>{`。`}</p>
    <Image {...props} name="presenting" alt="学生のプレゼンテーションの様子" mdxType="Image" />
    <h3 {...{
      "id": "コンバーチブルはエンターテイメントを楽しむならばありだが着脱式は厳しい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B3%E3%83%B3%E3%83%90%E3%83%BC%E3%83%81%E3%83%96%E3%83%AB%E3%81%AF%E3%82%A8%E3%83%B3%E3%82%BF%E3%83%BC%E3%83%86%E3%82%A4%E3%83%A1%E3%83%B3%E3%83%88%E3%82%92%E6%A5%BD%E3%81%97%E3%82%80%E3%81%AA%E3%82%89%E3%81%B0%E3%81%82%E3%82%8A%E3%81%A0%E3%81%8C%E7%9D%80%E8%84%B1%E5%BC%8F%E3%81%AF%E5%8E%B3%E3%81%97%E3%81%84",
        "aria-label": "コンバーチブルはエンターテイメントを楽しむならばありだが着脱式は厳しい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コンバーチブルはエンターテイメントを楽しむならばありだが着脱式は厳しい`}</h3>
    <Image {...props} name="convertible_s" alt="コンバーチブルノート" mdxType="Image" />
    <p><em parentName="p">{`コンバーチブル`}</em>{`は画像のように360度画面を回転させることができるパソコンである。`}</p>
    <p>{`対面で相手に画面を見せながら説明するなどでなければビジネスで活用できる場面は少ないが、タブレットとして寝転びながら動画を見る、イラストを描くなどエンターテイメントでは使える場面が多い。`}</p>
    <p>{`値段は通常タイプよりもやや張り、重量も重くなるケースも多いが、気になったならば購入を検討しても良い。`}<strong parentName="p">{`使い方の幅が広り授業でも自宅でくつろぐ時でも重宝する`}</strong>{`だろう。`}</p>
    <p>{`一方でキーボードと本体が分離できる`}<em parentName="p">{`着脱式`}</em>{`のモデルもあるが、これは安定性に問題があり、`}<strong parentName="p">{`膝の上や奥行きの狭い机で使いにくいものが多いためおすすめはできない`}</strong>{`。`}</p>
    <h3 {...{
      "id": "タッチパネルは基本不要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%BF%E3%83%83%E3%83%81%E3%83%91%E3%83%8D%E3%83%AB%E3%81%AF%E5%9F%BA%E6%9C%AC%E4%B8%8D%E8%A6%81",
        "aria-label": "タッチパネルは基本不要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`タッチパネルは基本不要`}</h3>
    <p>{`タッチパネルがあると専用のペンを使いノートをパソコンに取ることができ便利だという宣伝文句があるが本当にそうだろうか？`}</p>
    <p>{`特殊な大学ではそのような人もいるかもしれないが、少なくとも私は実際にノート代わりに使っている人は見たことがない。チカチカしてノートは取りづらいと思うので、それならば`}<a parentName="p" {...{
        "href": "https://amzn.to/3AbgYGK",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Kindle scribe`}</a>{`でも購入した方が良いだろう。`}</p>
    <p>{`また、Windows自体はタッチパネルに対応していても、スマホアプリとは異なり`}<strong parentName="p"><em parentName="strong">{`アプリケーションの多くはマウス操作を基本として作られているため、タッチパネルとの相性はそもそも悪い`}</em></strong>{`。`}</p>
    <p>{`電池の持ちも悪くなるので、`}<strong parentName="p"><em parentName="strong">{`対象者としては手軽にパソコンで絵を描きたい人`}</em></strong>{`ぐらいだろうか。`}</p>
    <Image {...props} name="drawing" alt="タッチパネル対応パソコンで絵を描く様子" mdxType="Image" />
    <h3 {...{
      "id": "その他大学生向けパソコンスペックのまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E5%A4%A7%E5%AD%A6%E7%94%9F%E5%90%91%E3%81%91%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AE%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "その他大学生向けパソコンスペックのまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他/大学生向けパソコンスペックのまとめ`}</h3>
    <p>{`以上をまとめると下記が推奨のスペックとなる。これを基準にしてパソコンを選んで行こう。`}</p>
    <AccentBox title="大学生向けパソコンスペック" mdxType="AccentBox">
  <li><span className="bold">サイズ</span>・・・13.3インチから14インチ</li>
  <li><span className="bold">CPU</span>・・・PassMark12500以上</li>
  <li><span className="bold">メモリ</span>・・・8GB。16GBは予算と相談</li>
  <li><span className="bold">ストレージ</span>・・・SSD512GB。256GBで外付けのHDDを容量が増えてきたら買うのもあり</li>
  <li><span className="bold">重量</span>・・・1kg(1000g)以下</li>
  <li><span className="bold">端子</span>・・・TypeA、Type-C、HDMI端子が揃っていると嬉しい。ただ変換器を持ち歩くのもあり。</li>
  <li><span className="bold">OS</span>・・・Windows11 Home Editionが一般的</li>
  <li><span className="bold">バッテリー</span>・・・12時間以上</li>
  <li><span className="bold">DVD/BDドライブ</span>・・・必要時に外付けを購入</li>
  <li><span className="bold">Office</span>・・・Microsoft Office Home and Business</li>
  <li><span className="bold">その他</span>・・・コンバーチブルは検討の余地あり</li>
    </AccentBox>
    <h2 {...{
      "id": "文系と理系でノートパソコンの必要スペックは変わらない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%96%87%E7%B3%BB%E3%81%A8%E7%90%86%E7%B3%BB%E3%81%A7%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E5%BF%85%E8%A6%81%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%81%AF%E5%A4%89%E3%82%8F%E3%82%89%E3%81%AA%E3%81%84",
        "aria-label": "文系と理系でノートパソコンの必要スペックは変わらない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`文系と理系でノートパソコンの必要スペックは変わらない`}</h2>
    <Image {...props} name="science_humanities" alt="文系と理系が対立する様子" mdxType="Image" />
    <p>{`理系では実験などで計算やグラフ作成を行う機会が多いため、パソコンはハイスペックなものを使い、文系では文書作成が主なため、低速なものでも構わないという主張がある。`}<strong parentName="p"><em parentName="strong">{`スマートフォンに例えるならば、理系は最新のiPhoneを使い、文系は低スペックな格安スマホで十分というぐらいナンセンスな主張`}</em></strong>{`である。`}</p>
    <p>{`実験でそれほどパソコンのスペックが必要となる作業は存在せず、あったとしてもそれは大学の専用パソコンを使用して行うことになる。`}</p>
    <p>{`また、モバイルノートはそもそもバッテリー駆動時間や熱の消費電力の関係からCPUの性能が低く、文系・理系を問わず、ある程度高価なものを搭載しておかないと4年間、場合によっては6年間を乗り越えられなくなる。`}</p>
    <p>{`このため`}<strong parentName="p"><em parentName="strong">{`文系だから、理系だからといった安易な理由でスペックを求めないこと`}</em></strong>{`が重要である。`}</p>
    <h2 {...{
      "id": "大学生におすすめのノートパソコン５選",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%A4%A7%E5%AD%A6%E7%94%9F%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%EF%BC%95%E9%81%B8",
        "aria-label": "大学生におすすめのノートパソコン５選 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`大学生におすすめのノートパソコン５選`}</h2>
    <p>{`それでは具体的なおすすめパソコンの紹介に入っていく。あまり選択肢が多いと絞れなくなるので、おすすめ順に５つ紹介したいと思う。`}</p>
    <p>{`それぞれノートパソコンはCPUやメモリをカスタマイズして選ぶことができるので、購入する時はPASSMARKのスコア等にも気を配りつつ選択をしていこう。`}</p>
    <h3 {...{
      "id": "富士通-LIFEBOOK-WU2J3--軟弱な大学生に刺さる軽量性",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AF%8C%E5%A3%AB%E9%80%9A-LIFEBOOK-WU2J3--%E8%BB%9F%E5%BC%B1%E3%81%AA%E5%A4%A7%E5%AD%A6%E7%94%9F%E3%81%AB%E5%88%BA%E3%81%95%E3%82%8B%E8%BB%BD%E9%87%8F%E6%80%A7",
        "aria-label": "富士通 LIFEBOOK WU2J3  軟弱な大学生に刺さる軽量性 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`富士通 LIFEBOOK WU2/J3 | 軟弱な大学生に刺さる軽量性`}</h3>
    <p>{`持ち運びが社会人と比べても多くなる大学生に特におすすめしたいノートパソコンが富士通のLIFEBOOKである。`}<strong parentName="p"><em parentName="strong">{`他メーカーと比べ軽量性は群を抜いており、本格的なノートパソコンであるにも関わらず、600 ~ 800グラム台のノートパソコンを販売`}</em></strong>{`している。`}</p>
    <p>{`特に`}<strong parentName="p"><em parentName="strong">{`WU2/J3は大学生をメインターゲットとして開発されている機種`}</em></strong>{`であり、750グラム程度の軽量性と高いコストパフォーマンスを兼ね揃える。`}</p>
    <p>{`1.3kg程度あるAppleのMacbook Airと比べると、スマホや電源用コード、教授に買わされたテキスト等を持ち歩いてもまだ余裕がある。`}</p>
    <p>{`TypeA、HDMI、写真の取り込みに必要なカードリーダーなどの端子も充実しており、`}<strong parentName="p"><em parentName="strong">{`キャンパスライフで日常的に使える製品としての死角は無い`}</em></strong>{`と言えるだろう。`}</p>
    <p>
      <Link to="/fujitsu/" mdxType="Link">特別クーポン</Link>(クーポンコード-> <b>SPLSJ</b> )を富士通様からいただいているため活用して欲しいが、アカウント登録をしてからの学割もお得のため面倒でなければ大学生向け特設ページから確認してほしい。
    </p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=2543&murl=https%3A%2F%2Fwww.fmv.com%2Fstore%2Fpc%2Fcustom%2F3275.html" target="_blank" rel="nofollow noopener">WU2/J3</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=1680851.1&type=10" />
 | <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=2543&murl=https%3A%2F%2Ffmv.fccl.fujitsu.com%2Fshop%2Fpc%2Fui3081.html" target="_blank" rel="nofollow noopener">大学生向け特設ページ</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=1284217.1&type=10" />
    </SmallBox>
    <h3 {...{
      "id": "HP-Pavilion-Aero-13--バランスの取れた構成",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#HP-Pavilion-Aero-13--%E3%83%90%E3%83%A9%E3%83%B3%E3%82%B9%E3%81%AE%E5%8F%96%E3%82%8C%E3%81%9F%E6%A7%8B%E6%88%90",
        "aria-label": "HP Pavilion Aero 13  バランスの取れた構成 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HP Pavilion Aero 13 | バランスの取れた構成`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`大学生に必要なパソコンスペックを全て満たした売れ筋モデル`}</em></strong>{`。HP内のサイトでも常にトップクラスの売れ行きを誇るのがこのPavilion Aero 13である。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`1kgを切る軽量`}</em></strong>{`に加え、優れたデザイン性、液晶の解像度も16:10と通常のものより縦が長く作業に適したものとなっており、コスパも十分に高い。`}</p>
    <p>{`TypeA端子やHDMIなど大学で必要となる基本的な端子も揃っているため接続で困ることもないだろう。`}</p>
    <p>{`なお、メールアドレス等を入力する必要があるが、`}<strong parentName="p"><em parentName="strong">{`HPの学割特典を使うと大幅な割引価格で購入することができる`}</em></strong>{`ため必ず利用したい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fnotebooks%2Fpersonal%2Fpavilion_aero_13_bg%2F" target="_blank" rel="nofollow noopener">HP Pavilion Aero 13-bg</a>
      <img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10" /> | <a href="https://click.linksynergy.com/deeplink?id=xsv0FDnJs1M&mid=35909&murl=https%3A%2F%2Fjp.ext.hp.com%2Fcampaign%2Fpersonal%2Fothers%2Fstudent_pc_collection%2F" target="_blank" rel="nofollow noopener">学割紹介ページ</a><img alt="icon" width="1" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=252926.1&type=10" />
    </SmallBox>
    <h3 {...{
      "id": "NEC-LAVIE-N13--気軽に持ち出せるコンパクトモバイルノート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#NEC-LAVIE-N13--%E6%B0%97%E8%BB%BD%E3%81%AB%E6%8C%81%E3%81%A1%E5%87%BA%E3%81%9B%E3%82%8B%E3%82%B3%E3%83%B3%E3%83%91%E3%82%AF%E3%83%88%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88",
        "aria-label": "NEC LAVIE N13  気軽に持ち出せるコンパクトモバイルノート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NEC LAVIE N13 | 気軽に持ち出せるコンパクトモバイルノート`}</h3>
    <p>{`13.3インチの軽量コンパクトモデルを探しているならばNECのモデルがコスパも考えるとちょうど良い構成となっている。`}</p>
    <p>{`何か尖った特長があるわけではないが、`}<strong parentName="p"><em parentName="strong">{`1kg程度の重量に豊富なインターフェース、カラーバリエーションがある洗練されたデザインと隙がない`}</em></strong>{`。`}</p>
    <p>{`更に国内販売数トップであるNECの安心感はサポートを含めて抜群であり、購入して間違いの無いモデルと言える。同様に学割も行なわれているので購入の際は利用したい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fproducts%2Fnotebook%2Flavie%2Fn13%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt="" />LAVIE N13</a> | <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fshop%2Fopinedu%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" rel="nofollow" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt="" />NEC 学割紹介ページ</a>
    </SmallBox>
    <h3 {...{
      "id": "マウスコンピューター-14インチノート--良コスパなハイスペックPC",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF%E3%83%BC-14%E3%82%A4%E3%83%B3%E3%83%81%E3%83%8E%E3%83%BC%E3%83%88--%E8%89%AF%E3%82%B3%E3%82%B9%E3%83%91%E3%81%AA%E3%83%8F%E3%82%A4%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AFPC",
        "aria-label": "マウスコンピューター 14インチノート  良コスパなハイスペックPC permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マウスコンピューター 14インチノート | 良コスパなハイスペックPC`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`全てのパソコンを国内で生産しており、サポートも２４時間対応で修理も早い`}</em></strong>{`ため、いざという時も安心なメーカーがマウスコンピューターである。`}</p>
    <p>{`重量は1.2kgを超えてくるものの、良心的な価格帯で14インチのPCを販売している。`}</p>
    <p>{`特にクリエイター向けパソコンであるDAIVシリーズはグラフィックボードを搭載し(Geforceと書かれていればNvidia製のグラフィックボードを搭載している)、`}<strong parentName="p"><em parentName="strong">{`快適な動画編集や高度な3Dゲーム、AI画像生成なども楽々こなせるスペック`}</em></strong>{`を持つ。それでいて持ち運び可能な1.4kg程度の重量で提供している売れ筋シリーズである。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`DAIVはあらゆる選択肢を除外せずにパソコンを使い倒したい人に是非おすすめしたいモデル`}</em></strong>{`である。学割は無いものの価格としては十分に良コスパである。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Fwww.mouse-jp.co.jp%2Fstore%2Fr%2Fra2020100%2F" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" alt="" />マウスコンピューター 14インチパソコン</a>
    </SmallBox>
    <h3 {...{
      "id": "Dell-Inspiron-14インチ--体力がある体育会系PC",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Dell-Inspiron-14%E3%82%A4%E3%83%B3%E3%83%81--%E4%BD%93%E5%8A%9B%E3%81%8C%E3%81%82%E3%82%8B%E4%BD%93%E8%82%B2%E4%BC%9A%E7%B3%BBPC",
        "aria-label": "Dell Inspiron 14インチ  体力がある体育会系PC permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dell Inspiron 14インチ | 体力がある体育会系PC`}</h3>
    <p>{`重量は1.5kg程度と持ち歩くにはギリギリの重さであり、カラーバリエーションなども存在しないが、`}<strong parentName="p"><em parentName="strong">{`価格帯で折り合い着かなければDellの14インチモデルは非常におすすめ`}</em></strong>{`である。`}</p>
    <p>{`オフィス付きで10万円程度でパソコンを選ぶことができ、スペックとしては他と遜色がないか、むしろ最先端のCPUを搭載している場合も多い。`}</p>
    <p>{`大学に持ち運びがないか、`}<strong parentName="p"><em parentName="strong">{`体力がある人ならば問題はない`}</em></strong>{`だろう。US出張などに行くとDell PCを軽々と持ち歩く人を多く目にするが、そういった人なら良いのではないか。`}</p>
    <p>{`オフィスはカスタマイズ項目として選択できる場合とできない場合があるので、選択可能なものを探す必要がある点は注意が必要である。複数種類あり、時々で価格が変化するためInprisonノート全体のリンクを貼っておく。選択ボックスから条件を選んで絞り込んでほしい。`}</p>
    <SmallBox type="word accent" text="公式サイトへ" mdxType="SmallBox">
  <a href="https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=296568.10008673&type=3&subid=0" rel="nofollow noopener" target="_blank">Dell Inspiron</a><img border="0" width="1" alt="" height="1" src="https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=296568.10008673&type=3&subid=0" />
    </SmallBox>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`大学生活に求められるパソコンのスペックと、おすすめのパソコンを紹介した。`}</p>
    <p>{`ある程度の価格は覚悟する必要があるが、本記事を参考に４年間使えるしっかりとしたパソコンを最初に購入して、快適な学生生活を送って欲しい。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      