import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/grabo-hikaku.png"}) { ...eyecatchImg },
    allGpuallJson {
      nodes {
        name
        passmark
        vmark
        fullHd
        qhd
        fourK
        tdp
        price
        cospa
        year
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`GPU(グラボ,グラフィックボード)性能比較表`}</h1>
    <time itemProp="datePublished" dateTime="Fri Jan 24 2025 23:40:10 GMT+0900 (日本標準時)">更新日:2025年1月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="GPU性能比較" mdxType="Image" />
    <p>{`本サイトでは200程度の定番及び最新のGPUに対し、簡単に性能の比較検討が行えるよう、信頼性の高いベンチマークである`}<a parentName="p" {...{
        "href": "https://www.videocardbenchmark.net/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`PassMark`}</a>{`、`}<a parentName="p" {...{
        "href": "https://benchmarks.ul.com/compare/best-gpus",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`3DMark`}</a>{`や、実測値の性能計測に特化したサイトである`}<a parentName="p" {...{
        "href": "https://www.gpucheck.com",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`GPUCHECK`}</a>{`からFPS(フレームレート)の情報を収集し、一覧として表にまとめている。`}</p>
    <p>{`この性能比較表を参照することで、`}<strong parentName="p"><em parentName="strong">{`どの程度3Dゲームを快適に遊ぶことができるか、どちらのグラフィック機能が優れているかがひと目で分かる`}</em></strong>{`ようになる。`}</p>
    <p>{`同様に`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`もあるのでGPUではなくCPUを調べたい時は参考にして欲しい。`}</p>
    <Meyasu mdxType="Meyasu" />
    <Accordion title="GPU型番の種類・末尾の意味一覧" mdxType="Accordion">
      <p>グラフィックボードのメーカーはNVIDIAとAMDの２社であり、それぞれが用途に応じたGPUを製造している。また、オンボードグラフィックというパソコンの基盤であるマザーボードにGPU能力が付与されたものもあり、CPUメーカーのインテルなどが別途開発している。</p>
      <p>オンボードグラフィックも含め、有名なものは以下の通りである。</p>
      <AccentBox title="グラフィックボードの種類" mdxType="AccentBox">
  <li><span className="bold">NVIDIA Geforce</span>・・・NVIDIAのゲーム用に最適化されたGPU、安定したパフォーマンスを誇り、シェアが断トツ。低スペックなものからハイスペックなものまで幅広いモデルが存在する </li>
  <li><span className="bold">AMD Radeon</span>・・・AMD社のゲーム用に最適化されたGPU、Geforceとよく比較される、こちらも低スペックなものからハイスペックなものまで幅広いモデルが存在する</li>
  <li><span className="bold">Intel Arc Graphics</span>・・・IntelのGPUシリーズ。ディスクリートなものとオンボードのものがある。ローエンドが主だがグラフィック機能を適度に補完できる。</li>
      </AccentBox>
      <p>次にGeforceの末尾の意味については以下のようになる。</p>
      <GeforceMatsubi mdxType="GeforceMatsubi" />
    </Accordion>
    <Accordion title="検索/フィルタリング方法の例・詳細" mdxType="Accordion">
      <p>ヘッダー部分をクリックすることでソートが行える。また、GPU名称はカンマ区切りをすることでor条件で調べられ、各スコアはレンジ(-)や不等号でフィルタリングすることができる。</p>
      <AccentBox title="フィルター方法の例(該当するフォームへ入力)" mdxType="AccentBox">
  <li><span className="bold">RTX 40, GTX 10</span>・・・RTX 4000台とGTX 1000台を比較</li>
  <li><span className="bold">GeForce, Radeon</span>・・・GeforceとRadeonを比較</li>
  <li><span className="bold">5000 - 10000</span>・・・スコアが5000から10000まで、-の間にはスペースが必要</li>
  <li><span className="bold">{'<'}100</span>・・・TDPが100W未満の省電力グラフィックボードのみを表示</li>
  <li><span className="bold">{'>'}200</span>・・・参考価格が200ドルより上</li>
      </AccentBox>
    </Accordion>
    <p>{`では下記に一覧表を示す。Passmarkスコアの目安も示しておくので参考にしてほしい。`}</p>
    <p>{`なお表のFPSは`}<strong parentName="p"><em parentName="strong">{`やや重めの3Dゲームを最高画質で遊んだ時の参考`}</em></strong>{`である。FF15やモンハンなどの重いゲームは高画質でその程度であり、最高画質で60FPSを出すには80ぐらい必要となる。`}</p>
    <p>{`一般的なパソコンモニタはFullHDで60FPS以上出力できないため、期待FPSがそれ以上となるグラボは専用のモニタや、解像度が高いモニタが無いと真価を発揮できないという点には注意しよう。`}</p>
    <AccentBox title="Passmarkの目安" mdxType="AccentBox">
  <li><span className="bold">25000~</span>・・・4K120FPSを目指したい時。レイトレーシングでもフレームレートを保ち続け最先端の映像体験を満喫。</li>
  <li><span className="bold">17000 - 25000</span>・・・4K60FPSやレイトレーシングの超美麗グラフィックや144Hzなど高FPSをコスパ良く体験。ゲーミングモニターが必須となる。</li>
  <li><span className="bold">11000 - 17000</span>・・・ほぼ全てのゲームをFullHD x 60FPSで遊ぶには十分な性能でVRにも対応。</li>
  <li><span className="bold">6000 - 11000</span>・・・ほぼ全てのゲームをFullHDで遊ぶことができるが画質調整は必要。VRにはやや力不足。</li>
  <li><span className="bold">3000 - 6000</span>・・・解像度やレンダリングのクオリティを十分に落とせば大体のゲームは遊べるが、ゲーミングPCは名乗れないレベル。</li>
  <li><span className="bold">0 - 3000</span>・・・あえて言おう、カスであると。マインクラフトやドラクエXなど軽いゲームなら遊べるものもあるが、多くのゲームは全くできないか最低画質でもカックカクになる。</li>
    </AccentBox>
    <HikakuTable {...props} checkboxes={['geforce', 'radeon']} cpuData={props.data.allGpuallJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      