import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from 'gatsby';
import HikakuTable from 'components/hikakuTableGPULoadable';
import Accordion from 'components/accordion';
import AccentBox from 'components/accentBox';
import Socials from 'components/socials';
import Meyasu from 'components/gpu/meyasu';
import GeforceMatsubi from 'components/gpu/geforce_matsubi';
import Image from "components/image";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "gpu/intel-arc"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/gpu/intel-arc.png"}) { ...eyecatchImg },
    allIntelArcJson {
      nodes {
        name
        vmark
        passmark
        fullHd
        qhd
        fourK
        tdp
        price
        cospa
        year
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Intel Arc 性能比較表`}</h1>
    <time itemProp="datePublished" dateTime="Fri Jan 24 2025 23:40:10 GMT+0900 (日本標準時)">更新日:2025年1月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Intel Arc 性能比較表" mdxType="Image" />
    <p>{`Intel ArcはCPUメーカーのIntelが開発したグラフィックチップであり、搭載されることでゲーム体験、AI処理、ビデオのエンコード処理など様々なパフォーマンス向上が期待される。`}</p>
    <p>{`NvidiaのGeforceやAMDのRadeonなど専用のGPUを搭載しない場合、IntelのArcグラフィックスを搭載することがゲームやクリエイティブ用途で良い選択肢となるが、IntelのArcシリーズもローエンドのものからハイエンドのものまで複数のラインナップが展開されており、性能比較が難しい。`}</p>
    <p>{`そこで本ページでは、複数種類存在するIntel Arcを信頼性の高いベンチマークである`}<a parentName="p" {...{
        "href": "https://www.videocardbenchmark.net/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`PassMark`}</a>{`をもとに順位付けし、ランキングとして表にまとめる。`}</p>
    <p>{`同様にNvidiaのGeforceやAMD Radeon GPUも含めた`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`や、`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`もあるので追加で調べたい場合は参考にして欲しい。`}</p>
    <h2 {...{
      "id": "Intel-Arcは薄型モバイルノートに対して強みがある",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Arc%E3%81%AF%E8%96%84%E5%9E%8B%E3%83%A2%E3%83%90%E3%82%A4%E3%83%AB%E3%83%8E%E3%83%BC%E3%83%88%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6%E5%BC%B7%E3%81%BF%E3%81%8C%E3%81%82%E3%82%8B",
        "aria-label": "Intel Arcは薄型モバイルノートに対して強みがある permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Arcは薄型モバイルノートに対して強みがある`}</h2>
    <p>{`比較表を紹介する前に所感を述べる。`}<a parentName="p" {...{
        "href": "https://www.intel.co.jp/content/www/jp/ja/products/details/discrete-gpus/arc.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Intel Arcシリーズ`}</a>{`はデスクトップ版のディスクリートCPUとノートパソコン版が存在するが、デスクトップ版に関しては評判が芳しくない。`}</p>
    <p>{`その理由は明白であり、`}<strong parentName="p"><em parentName="strong">{`デスクトップ版はグラフィック機能に対してコスパが大して良くない`}</em></strong>{`からである。実際に数万円するIntel ArcのGPUを買うならばNvidiaのGeforceを搭載した方がはるかに良いゲームのパフォーマンスを安定的に得ることができ、到底おすすめはできない。`}</p>
    <p>{`一方で強みがあるのはモバイルノートに搭載されるケースである。現状NvidiaのGeforceを搭載した本格モバイルゲーミングPCは重量が重く、持ち運びに適しているとは言えない。`}</p>
    <p>{`そこでIntel Arcの出番であり、`}<strong parentName="p"><em parentName="strong">{`モバイルの軽量性を損なうことなくクリエイティビティやゲーミング性能に優れたノートPCの基盤となることができる`}</em></strong>{`。個人的見解だが、ここにIntel Arcの存在意義がある。`}</p>
    <h2 {...{
      "id": "Intel-Arc-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Arc-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Intel Arc 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Arc 性能比較表`}</h2>
    <p>{`それでは下記に性能比較表を示す。また、やや主観も入るがPassMarkスコアの目安を下記に示しておくので参考にして欲しい。モバイル版はおしりにMがついているものや140VなどVがついているものである。`}</p>
    <p>{`リンク先はAmazonに飛ぶ。それぞれのグラフィックを搭載したパソコンを検討している場合に参考となるだろう。ゲームでどれぐらいのFPSが期待できるか、NvidiaのGPUと比較したいという場合は`}<Link to="/gpu/" mdxType="Link">{`GPU性能比較表`}</Link>{`を参照してほしい。`}</p>
    <AccentBox title="Passmarkの目安" mdxType="AccentBox">
  <li><span className="bold">25000~</span>・・・4K120FPSを目指したい時。レイトレーシングでもフレームレートを保ち続け最先端の映像体験を満喫。</li>
  <li><span className="bold">17000 - 25000</span>・・・4K60FPSやレイトレーシングの超美麗グラフィックや144Hzなど高FPSをコスパ良く体験。ゲーミングモニターが必須となる。</li>
  <li><span className="bold">11000 - 17000</span>・・・ほぼ全てのゲームをFullHD x 60FPSで遊ぶには十分な性能でVRにも対応。</li>
  <li><span className="bold">6000 - 11000</span>・・・ほぼ全てのゲームをFullHDで遊ぶことができるが画質調整は必要。VRにはやや力不足。</li>
  <li><span className="bold">3000 - 6000</span>・・・解像度やレンダリングのクオリティを十分に落とせば大体のゲームは遊べるが、ゲーミングPCは名乗れないレベル。</li>
  <li><span className="bold">0 - 3000</span>・・・あえて言おう、カスであると。マインクラフトやドラクエXなど軽いゲームなら遊べるものもあるが、多くのゲームは全くできないか最低画質でもカックカクになる。</li>
    </AccentBox>
    <HikakuTable {...props} cpuData={props.data.allIntelArcJson.nodes} fieldArray={[{
      id: 'name',
      placeholder: '例:GTX, RTX',
      filterType: 'string',
      css: {
        width: '130px'
      }
    }, {
      id: 'passmark',
      placeholder: '1 - 7000'
    }, {
      id: 'tdp'
    }, {
      id: 'price'
    }, {
      id: 'cospa'
    }, {
      id: 'year'
    }]} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      