import React from "react"
import styled from "@emotion/styled"
import { mq, pageWidth, color } from "../utils/style";
import brainImg from "./images/brain.svg";
import { Link } from "gatsby"

const Wrapper = styled.header({
  fontFamily: 'Comic Sans MS, serif, Droid Serif',
  [mq('mobile')]: {
    paddingTop: '10px',
    paddingBottom: '5px',
    borderBottom: 'solid #26a69b 1px',
    margin: '0px 10px',
    '.logo': {
      textAlign: 'center',
      fontSize: '2.4rem',
      'a': {
        textDecoration: 'none',
        color: color.main,
        lineHeight: '33px',
      },
      'a::before': {
        content: '""',/*何も入れない*/
        display: 'inline-block',
        height: '33px',
        width: '33px',
        backgroundImage: `url(${brainImg})`,
        verticalAlign: 'top',
        marginRight: '5px',
      },
      '.logo-subs': {
        fontSize: '1rem',
      }
    }
  },
  [mq('pc')]: {
    paddingTop: '25px',
    paddingBottom: '10px',
    width: '100%',
    margin: 'auto',
    minWidth: pageWidth,
    '.logo': {
      width: pageWidth,
      margin: 'auto',
      paddingLeft: '10px',
      fontSize: '2.9rem',
      'a' : {
        display: 'inline-block',
        textDecoration: 'none',
        color: color.main,
        lineHeight: '40px',
      },
      'a::before' : {
        content: '""',/*何も入れない*/
        display: 'inline-block',
        height: '40px',
        width: '40px',
        backgroundImage: `url(${brainImg})`,
        verticalAlign: 'top',
        marginRight: '10px',
      },
      '.logo-subs': {
        fontSize: '1.3rem',
      }
    }
  }
});

export default function Header() {
  return (
    <Wrapper>
      <div className="logo">
      <div className="logo-subs">パソコン選びを現役エンジニアが本音で語る</div>
      <Link to="/">PCRECOMMEND 2025</Link>
      </div>
    </Wrapper>
  );
}