import React, { useRef } from "react"
import styled from "@emotion/styled"
import { Global } from "@emotion/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import AdSense from 'react-adsense';
import {
  mq,
  pageWidth,
  contentWidth,
  pageWidthW,
  contentWidthW,
  navWidth,
  color
} from "../utils/style";
import Header from "./header"
import Navigator from "./navigator"
import StickyFooter from "./stickyFooter"
import GotoTop from "./gotoTop"
import SEO from "./seo"
import BreadCrumb from "./breadCrumb"

const ContentWrapper = styled.div(
  {
    [mq('mobile')] : {
    },
    [mq('pc')]: {
      width: pageWidth,
      display: 'flex',
      margin: 'auto',
      '#primary' : {
        marginRight: '12px', // 1000 - 678 - 310
        flexBasis: contentWidth,
        flexShrink: 0,
      },
      '#secondary' : {
        flexBasis: navWidth,
        flexShrink: 0,
      }
    },
  }, props => {
    if (props.isWide) {
      return {
        [mq('pc')]: {
          width: pageWidthW,
          '#primary' : {
            flexBasis: contentWidthW,
          }
        }
      }
    }
  }
);

const Footer = styled.div({
  clear: 'both',
  borderTop: '3px solid #CCCCCC',
  textAlign: 'center',
  [mq('mobile')] : {
    padding: '10px 0',
  },
  [mq('pc')]: {
    margin: 'auto',
    width: pageWidth,
    padding: '10px 0',
  }
})

export default function Layout(props) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            pages {
              path
              title
              ltitle
              description
              tags
              category
              image
              main
              isWide
              noIndex
            }
          }
        }
      }
    `
  );
  const topEl = useRef(null);
  const moveToElement = (selector) => {
    if (selector === '#top') {
      topEl.current.scrollIntoView();
      return;
    }
    topEl.current.querySelector(selector).scrollIntoView();
  };

  let path = props.path;
  if (path === '/*') { // for production build
    path = props.pageContext && props.pageContext.frontmatter && props.pageContext.frontmatter.path;
  }
  const pages = data.site.siteMetadata.pages;
  const page = pages.find((page) => {
    return page.path === path;
  });
  const headings = props.data.allMdx?.nodes[0].headings;


  return (
    <div id="top" ref={topEl}>
      <Global
        styles={{
          html: {
            fontSize: '62.5%',
            width: '100%',
            margin: '0px',
            color: '#444444',
          },
          body: {
            fontSize: '1.6rem',
            margin: '0px',
          },
          p: {
            wordBreak: 'break-word',
            lineHeight: '2.7rem',
            marginBottom: '2.7rem',
            [mq('mobile')]: {
              marginLeft: '10px',
              marginRight: '10px',
            }
          },
          h1: {
            margin: '0 0 10px 0',
            fontSize: '2.0rem',
            fontWeight: 'bold',
            counterReset: 'head',
            [mq('mobile')]: {
              marginLeft: '10px',
              marginRight: '10px',
            }
          },
          'h2:before': {
            counterIncrement: 'head',
            content: 'counter(head)". "',
          },
          'h2:after': {
            content: '""',
            position: 'absolute',
            borderTop: `10px solid ${color.main}`,
            borderRight: '8px solid transparent',
            borderLeft: '8px solid transparent',
            bottom: '-8px',
            left: '20px',
          },
          h2: {
            position: 'relative',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            padding: '12px',
            background: color.main,
            '&.accent': {
              background: color.accent,
              '&:after': {
                borderTop: `10px solid ${color.accent}`,
              },
              '&:before': {
                counterIncrement: 'none',
                content: '""'
              },
            },
            color: 'white',
            paddingTop: '20px',
            marginBottom: '10px',
            borderRadius: '5px',
            [mq('mobile')]: {
              marginLeft: '10px',
              marginRight: '10px',
            }
          },
          h3: {
            position: 'relative',
            paddingLeft: '25px',
            paddingTop: '30px',
            fontWeight: 'bold',
            color: '#494949',
            [mq('mobile')]: {
              marginLeft: '10px',
              marginRight: '10px',
            }
          },
          'h3:before': {
            position: 'absolute',
            content: '""',
            bottom: '-3px',
            left: '0',
            width: '0',
            height: '0',
            border: 'none',
            borderLeft: 'solid 15px transparent',
            borderBottom: `solid 15px ${color.main}`,
          },
          'h3:after': {
            position: 'absolute',
            content: '""',
            bottom: '-3px',
            left: '10px',
            width: 'calc(100% - 10px)',
            borderBottom: `solid 3px ${color.main}`,
          },
          '.anchor.before': {
            top: 'unset',
          },
          strong: {
            [mq('mobile')]: {
              background: 'linear-gradient(transparent 25%,#ff6 25%)',
            },
            [mq('pc')]: {
              background: '#ff0',
            },
            fontWeight: '400',
          },
          em: {
            fontStyle: 'normal',
            fontWeight: 'bold',
          },
          '.bold': {
            fontStyle: 'normal',
            fontWeight: 'bold',
          },
          '.width100': {
            width: '100%',
          },
          '.center': {
            textAlign: 'center',
          },
          '.left': {
            textAlign: 'left',
          },
          '.left-margin': {
            marginLeft: '10px',
          },
          '.top-margin': {
            marginTop: '10px',
          },
          '.bottom-margin': {
            marginBottom: '10px',
          },
          '.no-wrap': {
            whiteSpace: 'nowrap',
          },
          '.mobile-margin': {
            [mq('mobile')]: {
              marginLeft: '10px',
              marginRight: '10px',
            }
          },
          '.pc-only': {
            [mq('mobile')]: {
              display: 'none',
            }
          }
        }}
      />
      <SEO page={page} />
      <Header/>
      <ContentWrapper
        isWide={page.isWide}
      >
        <div id="primary">
          {page && <BreadCrumb page={page} />}
          {props.children}
          <div className="top-margin center">
            {/* パソコンレスポンシブ右 */}
            <AdSense.Google
              client='ca-pub-7061143403016575'
              slot='9144837378'
              format='auto'
            />
          </div>
          <p>{ path !== "/" && <Link to="/">トップページヘ行きパソコン購入の推薦を受ける</Link> }</p>
        </div>
        <div id="secondary">
          <Navigator path={path} page={page} pages={pages} headings={headings}/>
        </div>
      </ContentWrapper>
      <Footer>Copyright &copy; パソコン選び方ガイド 2008-2025 All Rights Reserved.</Footer>
      <GotoTop moveToElement={moveToElement}/>
      <StickyFooter page={page} moveToElement={moveToElement}/>
    </div>
  )
}