import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Accordion from 'components/accordion';
import Image from "components/image";
import Socials from "components/socials";
import TOC from "components/toc";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import IntelMatsubi from 'components/cpu/intel_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
import FlatButton from "components/flatbutton";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/core-i"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/core.png"}) { ...eyecatchImg },
    generation: file(relativePath: { eq: "cpu/generation.png"}) { ...normalImg },
    alphabet: file(relativePath: { eq: "cpu/alphabet.png"}) { ...normalImg },
    corebrand: file(relativePath: { eq: "cpu/intel-core-brand.png"}) { ...normalImg },
    allCoreIJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Coreシリーズ比較表 | 世代や用途別にIntel CPUの性能を比較`}</h1>
    <time itemProp="datePublished" dateTime="Fri Jan 24 2025 23:40:06 GMT+0900 (日本標準時)">更新日:2025年1月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Coreシリーズ比較" mdxType="Image" />
    <p>{`CoreシリーズはIntelのミドルスペック以上のCPUブランド名であるが、i3,i5,i7,i9といったグレード分けや世代、用途の違いなどで細かく分かれ、その種類は100を軽く超える。`}</p>
    <p>{`また、2023年末にCoreシリーズのリブランディングが行われ、従来のi3,i5などからiが消え、14世代まで進んだ世代がリセットされてCore Ultraが誕生するなど、さらに複雑さを増している。`}</p>
    <p>{`そこで本ページでは世代や末尾文字などを新旧合わせて整理した上で比較表を見て、搭載すべきCPUを選べるようになることを目指していきたい。`}</p>
    <p>{`比較表をサクッと確認したい場合は下記のボタンで推して欲しい。廉価版のCeleronやAMD社のRyzenなど他のCPUも合わせて比較したい場合は`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`へ。`}</p>
    <div style={{
      textAlign: 'center',
      marginBottom: '10px'
    }}>
      <FlatButton onClick={() => document.querySelector('#hikaku').scrollIntoView()} className="top-margin" mdxType="FlatButton">Coreシリーズ性能比較表へ</FlatButton>
    </div>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Intel-Coreブランドと世代",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Core%E3%83%96%E3%83%A9%E3%83%B3%E3%83%89%E3%81%A8%E4%B8%96%E4%BB%A3",
        "aria-label": "Intel Coreブランドと世代 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Coreブランドと世代`}</h2>
    <Image {...props} name="generation" alt="Intel Core CPUのグレードと世代" mdxType="Image" />
    <p>{`まず重要なことはグレードと世代を理解することである。`}<strong parentName="p">{`グレードはシリーズの価格帯別のラインナップを表し、`}<em parentName="strong">{`Core i3、Core i5、Core i7、Core i9と数値が大きいほどハイエンドなCPU`}</em>{`となる`}</strong>{`。`}</p>
    <p>{`次に世代は、ほぼ`}<strong parentName="p"><em parentName="strong">{`毎年刷新されるアーキテクチャが最初から数えて何番目かを示す`}</em></strong>{`。Core i7-14700Kならば、シリーズ名のi7に続く14が世代を表す。`}</p>
    <p>{`ただし`}<strong parentName="p"><em parentName="strong">{`2023年末のCore iシリーズのリブランディングの結果、上位シリーズのCore Ultraと下位シリーズのCoreの２つが誕生し、Core Ultra 9 185Hなど再び第１世代から始まる`}</em></strong>{`こととなった。数値が大きいほどハイエンドというルール自体に変更はない。世代で呼ぶことも公式には廃止されたが、変わりの適当な名前もないため、便宜上本サイトでは`}<em parentName="p">{`新第１世代`}</em>{`と呼ぶことにする。`}</p>
    <Image {...props} name="corebrand" alt="Intel Coreブランドの再定義" mdxType="Image" />
    <p>{`当然新しいほど改良が加えられて性能が向上し、`}<strong parentName="p">{`世代が変わると年にもよるが`}<em parentName="strong">{`15%程度性能が上がる`}</em>{`ことが多い`}</strong>{`。世代ごとにロゴが変わるので、それで区別を付ける猛者もいる。`}</p>
    <p>{`また、世代ごとに`}<em parentName="p">{`開発コードネーム`}</em>{`が付けられており、下記のネーミングでCPUが宣伝される事も多い。`}</p>
    <BasicTable mdxType="BasicTable">
  <tbody>
    <tr>
      <th>世代</th>
      <th>開発コードネーム</th>
    </tr>
    <tr>
      <td>新第２世代(ノート用)</td>
      <td>Lunar Lake</td>
    </tr>
    <tr>
      <td>新第２世代(デスクトップ用)</td>
      <td>Arrow Lake</td>
    </tr>
    <tr>
      <td>新第１世代</td>
      <td>Meteor Lake</td>
    </tr>
    <tr>
      <td>第14世代</td>
      <td>Raptor Lake Refresh</td>
    </tr>
    <tr>
      <td>第13世代</td>
      <td>Raptor Lake</td>
    </tr>
    <tr>
      <td>第12世代</td>
      <td>Alder Lake</td>
    </tr>
    <tr>
      <td>第11世代(ノート用)</td>
      <td>Tiger Lake</td>
    </tr>
    <tr>
      <td>第11世代(デスクトップ用)</td>
      <td>Rocket Lake-S</td>
    </tr>
    <tr>
      <td>第10世代</td>
      <td>Ice Lake</td>
    </tr>
    <tr>
      <td>第9世代</td>
      <td>Cofee Lake Refresh-S</td>
    </tr>
    <tr>
      <td>第8世代</td>
      <td>Cofee Lake</td>
    </tr>
  </tbody>
    </BasicTable>
    <h3 {...{
      "id": "CPUのグレードと世代による性能差に関する一般論",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#CPU%E3%81%AE%E3%82%B0%E3%83%AC%E3%83%BC%E3%83%89%E3%81%A8%E4%B8%96%E4%BB%A3%E3%81%AB%E3%82%88%E3%82%8B%E6%80%A7%E8%83%BD%E5%B7%AE%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E4%B8%80%E8%88%AC%E8%AB%96",
        "aria-label": "CPUのグレードと世代による性能差に関する一般論 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CPUのグレードと世代による性能差に関する一般論`}</h3>
    <p>{`最新世代のCore 5は前世代(1世代前)のCore 7を上回るかと問われると、`}<strong parentName="p"><em parentName="strong">{`大抵のケースでは前世代のCore 7の方が性能が高い`}</em></strong>{`。`}</p>
    <p>{`ただ、`}<strong parentName="p"><em parentName="strong">{`Core 7は性能の割に高い傾向があり、コストパフォーマンスを考えるとCore 5に軍配`}</em></strong>{`が上がる。`}</p>
    <p>{`傾向を見るとこれらが一般的であるが、実際のベンチ―マーク結果をみると、しばしば最新世代のCore 5が前世代のCore 7を凌駕するなど逆転現象もおきているので、吟味する場合はスコアをきっちり確認することが肝要である。`}</p>
    <h2 {...{
      "id": "Intel-Core-CPUの末尾文字の意味",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Core-CPU%E3%81%AE%E6%9C%AB%E5%B0%BE%E6%96%87%E5%AD%97%E3%81%AE%E6%84%8F%E5%91%B3",
        "aria-label": "Intel Core CPUの末尾文字の意味 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Core CPUの末尾文字の意味`}</h2>
    <p>{`グレードと世代の他で重要な要素が末尾文字である。Core Ultra 9 285KならばKが末尾文字であり、`}<em parentName="p">{`末尾文字は意味がない場合もあるが、主に用途を表す`}</em>{`。すなわち`}<strong parentName="p"><em parentName="strong">{`どのようなターゲットに向けてパソコンが設計されているかを示している`}</em></strong>{`のである。`}</p>
    <p>{`末尾文字は複数あるが、代表的なものを図示すると次のようになる。`}</p>
    <Image {...props} name="alphabet" alt="CPUの末尾の文字を理解する" mdxType="Image" />
    <p>{`図のように消費電力と性能を軸に整理すると頭に入りやすい。`}</p>
    <p>{`デスクトップ向けのCPUは、通常版、設定によりパフォーマンスの最大値を変更できるオーバークロック版の他、`}<strong parentName="p">{`グラフィックボードを搭載する事が前提の`}<em parentName="strong">{`F版`}</em></strong>{`も存在する。`}</p>
    <p>{`F版はグラフィック機能を備えていない分価格が抑えられているのでグラボの搭載を前提とするならばコスパが高いモデルといえる。`}<em parentName="p">{`KF`}</em>{`というオーバークロックと組み合わせたモデルもある。`}</p>
    <p>{`次にノートは`}<strong parentName="p">{`ゲームを快適に行うのに適した`}<em parentName="strong">{`H`}</em></strong>{`、`}<strong parentName="p">{`動画編集を快適に行え、ゲームもそこそこ動く`}<em parentName="strong">{`V`}</em>{`、`}<em parentName="strong">{`G`}</em>{`、`}<em parentName="strong">{`P`}</em></strong>{`、`}<strong parentName="p">{`低消費電力でモバイル用途やオフィス作業に適した`}<em parentName="strong">{`U`}</em></strong>{`などがある。`}</p>
    <p><strong parentName="p">{`GとPとVはAI機能が強化されており、リモート会議のノイズ除去や背景隠しなどにも有効なことから、`}<em parentName="strong">{`パワフルなテレワーク用ノートパソコンとしても人気が高い`}</em></strong>{`。一押しのシリーズと言える。`}</p>
    <p>{`また`}<em parentName="p">{`V`}</em>{`は特に`}<strong parentName="p"><em parentName="strong">{`NPUというAI専用のプロセッサーを積んでおり`}</em></strong>{`、AIを使った画像生成、より分かりやすい検索機能などがアプリケーションで使えるようになる。`}</p>
    <h2 {...{
      "id": "Coreシリーズ性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Coreシリーズ性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Coreシリーズ性能比較表`}</h2>
    <p>{`おおよそCPU名称(型番)が理解できたかと思うため、Core シリーズ(`}<em parentName="p">{`Core i`}</em>{`、`}<em parentName="p">{`Core`}</em>{`、`}<em parentName="p">{`Core Ultra`}</em>{`)の性能比較表を示す。`}</p>
    <p>{`当サイトではベンチマークとして、CPUの総合的な能力を示す指標であるPassMark(M)と、CPUの単一の計算ユニットの能力(シングルスレッド能力)を示すPassMark(S)を掲載している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`基本的にはPassMark(M)を下記目安とともに参考にすれば大きく外さない`}</em></strong>{`だろう。ただ、性能差はPassMark(M)だけでは分からず、特に`}<strong parentName="p"><em parentName="strong">{`シングルスレッド能力はアプリのサクサク感やゲーム性能との相関が強いため、PassMark(S)も重要な指標である`}</em></strong>{`。`}</p>
    <p>{`また末尾の文字に関して、VシリーズはPassMarkは伸びていないがAI関連機能に強みをもつ、Hシリーズは消費電力が高くバッテリーの持ちが悪いなどを自身の用途と照らし合わせて考えることも必要な場合があるだろう。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`その他、表の項目説明等はまとめておくので必要に応じて参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <TableDescription mdxType="TableDescription" />
    <Accordion title="Intel CPU末尾文字の意味一覧" mdxType="Accordion">
      <IntelMatsubi mdxType="IntelMatsubi" />
    </Accordion>
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'notedesk']} cpuData={props.data.allCoreIJson.nodes} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      