import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Accordion from 'components/accordion';
import Image from "components/image";
import Socials from "components/socials";
import TOC from "components/toc";
import List from "components/list";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import IntelMatsubi from 'components/cpu/intel_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/core-i3"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/corei3.png"}) { ...eyecatchImg },
    alphabet: file(relativePath: { eq: "cpu/alphabet.png"}) { ...normalImg },
    allCoreIJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Core i3 比較表 | 世代や用途ごとにCore i3の性能を比較`}</h1>
    <time itemProp="datePublished" dateTime="Fri Jan 24 2025 23:40:06 GMT+0900 (日本標準時)">更新日:2025年1月24日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Core i3 比較" mdxType="Image" />
    <p>{`Core i3はIntelのCoreシリーズでは最も性能が低く、Celeronの上、Core i5の下というローからミドルエンドの性能を持ったCPUである。`}</p>
    <p>{`メインのパソコンとして使いたいけれど、価格は極力抑えたいという理由からCore i3が選択されることが多いが、製品の発売時期による世代やデスクトップ向け、ノート向けなど用途の違いにより性能は大きく上下する。`}</p>
    <p>{`果たして本当にCore i3で良いのか？とパソコンを検討中に悩んだなら本記事及び最後の性能比較表をチェックし、妥当であるかを良く確認して最終的にCPUを判断していこう。`}</p>
    <p>{`Core シリーズについての概要を知りたい人は`}<Link to="/cpu/core-i/" mdxType="Link">{`Coreシリーズ比較表`}</Link>{`へ、またAMDのCPU RyzenやCeleronなど全てのCPUとの比較は本家`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`で確認して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Core-i3の用途別性能差",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i3%E3%81%AE%E7%94%A8%E9%80%94%E5%88%A5%E6%80%A7%E8%83%BD%E5%B7%AE",
        "aria-label": "Core i3の用途別性能差 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i3の用途別性能差`}</h2>
    <Image {...props} name="alphabet" alt="CPUの末尾の文字を理解する" mdxType="Image" />
    <p>{`基本的にCPUは消費電力が大きいほど性能が高くなる。Core i3ではデスクトップ向けの65Wの通常版からCPUからノートの10Wを切る超消費電力版まで幅広いラインナップがされている。`}</p>
    <p>{`その差は非常に大きく、`}<strong parentName="p">{`低消費電力のCore i3のYシリーズは5世代前、すなわち５年前に発売された通常版のCore i3に敵わない`}</strong>{`というベンチマーク結果も出ている。`}</p>
    <p>{`また、ハイエンド向けへオーバークロックという、限界を超えて動作周波数を上げる機能を持つKや、ゲーミングノート向けのHはCore i3では発売されていない。`}</p>
    <h2 {...{
      "id": "Core-i3の一般的特徴と誤解",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i3%E3%81%AE%E4%B8%80%E8%88%AC%E7%9A%84%E7%89%B9%E5%BE%B4%E3%81%A8%E8%AA%A4%E8%A7%A3",
        "aria-label": "Core i3の一般的特徴と誤解 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i3の一般的特徴と誤解`}</h2>
    <p>{`用途別の性能が一般的に理解されておらず、`}<strong parentName="p"><em parentName="strong">{`Core i3は遅いからやめておいた方が良いと言われる事が多いがナンセンス`}</em></strong>{`である。`}</p>
    <p>{`デスクトップ向けのCore i3は一般的な事務用途、テレビ会議、Webブラウジング等に対して十分な能力を発揮でき、高度なゲームや動画編集についてもそれなりには対応できる。`}</p>
    <p>{`５万円程度でできるライトなゲーミングパソコンのCPUとしても採用されることも多い。`}</p>
    <p>{`ノートパソコンはやや非力な感があり、私も事務用途では積極的には選ばないが、13世代のCore i3-1315Uなど事務作業としては十分すぎる馬力のCPUも発売されている。`}</p>
    <h2 {...{
      "id": "Core-i3とCore-i5の違いは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i3%E3%81%A8Core-i5%E3%81%AE%E9%81%95%E3%81%84%E3%81%AF%EF%BC%9F",
        "aria-label": "Core i3とCore i5の違いは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i3とCore i5の違いは？`}</h2>
    <p>{`CPUの末尾文字が変わると性能差が大きく変わるので、違いを一概に答えることはできないが傾向としては`}</p>
    <List mdxType="List">
  <li>最新世代のCore i3が１世代前のCore i5にやや劣る程度であることが多い。</li>
  <li>ベンチマークのスコアを価格で割ったコストパフォーマンスではCore i5に軍配が上がることが多いがCore i3もまあまあ健闘。</li>
    </List>
    <p>{`ぐらいか。とはいっても傾向でしか語ることができないので、`}<strong parentName="p"><em parentName="strong">{`同世代、同用途であればCore i3よりCore i5の方が高速という結論しか出せない`}</em></strong>{`。基本的には性能比較表で判断すべきである。`}</p>
    <p>{`因みにCeleronはメインとして張るCPUではないので、`}<strong parentName="p"><em parentName="strong">{`Celeron <<< Core i3 < Core i5`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "Intel-Core-i3-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Core-i3-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Intel Core i3 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Core i3 性能比較表`}</h2>
    <p>{`それではCore i3 CPUの様々な世代、用途における性能比較表を示す。Core i5や他のCPUと比較したい場合はチェックボックスを利用して欲しい。`}</p>
    <p>{`ベンチマークとして総合的な能力を計測するPassMarkの総合スコアPassMark(M)とシングルスレッドの性能を測るPassMark(S)を掲載している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`基本的にはPassMark(M)を下記目安とともに参考にすれば良い`}</em></strong>{`だろう。ただ、PASSMARKの値はベンチマークバージョンにより上下するため、他サイトのスコアは参考にならないので注意しておこう。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`その他、表の項目説明等はまとめておくので必要に応じて参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <TableDescription mdxType="TableDescription" />
    <Accordion title="Intel CPU末尾文字の意味一覧" mdxType="Accordion">
      <IntelMatsubi mdxType="IntelMatsubi" />
    </Accordion>
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'notedesk']} cpuData={props.data.allCoreIJson.nodes} checkStatus={{
      corethree: true
    }} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      